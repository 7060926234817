@import '../../../styles/shared';

.c-video-player {
  iframe {
    @include overlay;

    width: 100%;
    height: 100%;
  }
}
