@import '../../../../../styles/shared';

.c-contact-details {
  &__item {
    position: relative;
    @include padding-left(27px);
    margin-bottom: 7px;
  }

  &__label {
    &::before {
      position: absolute;
      @include left(5px);
      top: -2px;
      width: 9px;
      height: 18px;
      content: '';
      border-style: solid;
      color: #d6d6d6;

      [dir='ltr'] & {
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }

      [dir='rtl'] & {
        border-width: 0 0 2px 2px;
        transform: rotate(-45deg);
      }
    }

    &--completed::before {
      color: #092864;
    }
  }

  &__link {
    line-height: 34px;

    &--error {
      color: $form-error-color;
    }
  }
}
