@import '../../../../../../styles/shared';

@include one-of-themes([$FANEXPOHQ, $MEFCC]) {
  .c-fan-expo-hq-footer-pages {
    &__link {
      color: #fff;
      font-size: 13px;
      letter-spacing: 0;
      line-height: 18px;
      text-decoration: underline;
    }
  }
}

@include one-of-themes(
  [$ONEOFAKIND,
  $INTERIORDESIGN,
  $ARTTORONTO,
  $THEARTISTPROJECT,
  $NAJAHSTACK]
) {
  .c-fan-expo-hq-footer-pages {
    &__link {
      color: #fff;
      font-size: 14px;
      font-weight: 100;
      line-height: 22px;
      text-decoration: underline;
    }
  }
}
