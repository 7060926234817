@import '../../../../styles/shared';

.c-login-form-horizontal {
  &__wrapper {
    display: flex;
    align-items: baseline;
    justify-content: space-between;

    @include mq($until: tablet) {
      flex-direction: column;
    }
  }

  &__field {
    flex: 0 0 calc(50% - 20px);

    @include mq($until: tablet) {
      width: 100%;
      margin-bottom: 30px;
    }
  }

  &__heading {
    color: #333;
    font-size: 22px;
    font-weight: 300;
    line-height: 28px;
    margin-bottom: 26px;
  }
}
