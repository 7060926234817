@import '../../../../../../styles/shared';

@include one-of-themes(
  [$FANEXPOHQ,
  $ONEOFAKIND,
  $NAJAHSTACK,
  $INTERIORDESIGN,
  $ARTTORONTO,
  $THEARTISTPROJECT,
  $MEFCC]
) {
  .c-fan-expo-hq-footer-social {
    &__icon {
      font-size: 13px;
      color: #fff;
      width: 14px;
      height: 14px;
      text-align: center;
    }

    &__link {
      color: #fff;
      font-size: 13px;
      letter-spacing: 0;
      line-height: 22.5px;
    }

    &__list-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__icon {
      @include margin-right(10px);
    }
  }
}

@include one-of-themes(
  [$ONEOFAKIND,
  $NAJAHSTACK,
  $INTERIORDESIGN,
  $ARTTORONTO,
  $THEARTISTPROJECT]
) {
  .c-fan-expo-hq-footer-social {
    &__link {
      font-size: 14px;
      line-height: 22px;
      font-weight: 100;
    }
  }
}
