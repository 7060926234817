@import '../../../../../../styles/shared';

@include one-of-themes([$FANEXPOHQ, $MEFCC]) {
  .c-fan-expo-hq-footer-title {
    color: #fff;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0.43px;
    line-height: 18px;
    text-transform: uppercase;
    margin-bottom: 5px;
  }
}

@include one-of-themes(
  [$ONEOFAKIND,
  $NAJAHSTACK,
  $INTERIORDESIGN,
  $ARTTORONTO,
  $THEARTISTPROJECT]
) {
  .c-fan-expo-hq-footer-title {
    color: #fff;
    font-size: 14px;
    line-height: 22px;
    font-weight: bold;
    text-transform: capitalize;
    margin-bottom: 10px;
  }
}
