@import '../../../../styles/shared';

@include one-of-themes([$FANEXPOHQ, $MEFCC]) {
  $grey-color: #757575;
  $blue-color: #0866db;
  $border-bg: #979797;

  /* Those styles below we use as extension for navigation tabs */
  .nav-tabs-style-extension {
    display: flex;
    margin-bottom: 30px;
    @include mq($until: desktop) {
      flex-direction: column;
      align-items: flex-start;
    }

    li {
      margin-right: 23px;
      @include mq($until: desktop) {
        margin-bottom: 8px;
        margin-right: 0;
      }

      a {
        color: $grey-color;
        font-family: 'Proxima Nova ExCn Rg', sans-serif;
        font-size: 22px;
        font-weight: bold;
        line-height: 27px;
        text-decoration: none;
        text-transform: uppercase;

        @include theme-only($MEFCC) {
          font-family: 'Barlow Condensed Extra Bold', sans-serif;
        }
      }

      &:after {
        position: relative;
        content: '';
        height: 27px;
        border-right: 1px solid $border-bg;
        left: 13px;
        box-sizing: border-box;
        @include mq($until: desktop) {
          content: none;
        }
      }

      &:last-child {
        &:after {
          content: none;
        }
      }

      &:hover {
        a {
          color: #000;
        }
      }
    }

    .active {
      a {
        color: #000;
      }
    }
  }

  /* Those styles below we use as extension for tab content */
  .tab-content-style-extension {
    .tab-pane {
      display: none;
    }

    .tab-pane.active {
      display: block;

      hr {
        margin: 25px 0;
        background: $border-bg;
        &:last-child {
          display: none;
        }
      }

      h3.text-success {
        color: $grey-color;
        font-family: 'Proxima Nova ExCn Rg', sans-serif !important;
        font-size: 22px;
        font-weight: bold;
        line-height: 22px;
        margin-bottom: 10px;
      }

      .media {
        display: flex;

        .media-left {
          width: initial !important;
          padding-right: 19px;
        }

        .media-body {
          color: $grey-color;
          font-size: 14px;
          line-height: 19px;
          flex-basis: 100%;

          hr {
            visibility: hidden;
            margin: 0;
          }

          h4.media-heading.gt-expand-schedule {
            color: #000;
            font-size: 16px;
            font-weight: bold;
            line-height: 21px;

            a.btn {
              display: inline-block;
              font-size: 14px;
              line-height: 19px;
              text-decoration: underline;
              color: $blue-color;
              font-weight: normal;
              margin-left: 10px;
              @include mq($until: desktop) {
                margin-left: 0;
              }

              &:after {
                position: relative;
                left: 6px;
                top: -1px;
                content: '';
                border: solid $blue-color;
                border-width: 0 2px 2px 0;
                display: inline-block;
                padding: 4px;
                transform: rotate(45deg);
                -webkit-transform: rotate(45deg);
              }
            }
          }

          strong {
            display: inline-block;
            color: $grey-color;
            font-size: 14px;
            line-height: 19px;
            font-weight: normal;
            margin: 5px 0 23px 0;
            @include mq($until: desktop) {
              margin: 10px 0 5px 0;
            }
          }

          .gt_schedule_guests {
            margin-top: 15px;

            .media {
              margin-bottom: 18px;
              .media-left {
                padding-right: 13px !important;
                img {
                  width: 32px;
                  height: 32px;
                }
              }

              .media-body {
                a {
                  color: #000;
                }

                h4.media-heading {
                  color: #000;
                  font-size: 16px;
                  font-weight: bold;
                  line-height: 21px;
                  padding-top: 6px !important;
                }
              }
            }
          }
        }
      }
    }
  }

  /* Styles for SCHEDULE page */
  .v-grow-tix-view__schedule {
    color: #000;
    padding: 42px 0;

    &--with-heading {
      padding-top: 0;
    }

    #gt_content {
      border-top: 1px solid $border-bg;

      &:first-child .row {
        margin-right: 0;
        margin-left: -20px;
        @include mq($until: desktop) {
          margin-right: -10px;
          margin-left: -10px;
        }
      }
    }

    .col-sm-4,
    .col-sm-8 {
      padding-left: 20px;
      padding-right: 20px;
      @include mq($until: desktop) {
        flex-basis: 100%;
        max-width: 100%;
        padding-left: 10px;
        padding-right: 10px;
      }
    }

    #gt_schedule {
      hr {
        background: $border-bg;
      }

      h4 {
        color: $blue-color;
        font-size: 14px;
        line-height: 22px;

        #gt_reset_schedule {
          border: 1px solid $grey-color;
          border-radius: 6px;
          background-color: $grey-color;
          color: #fff;
          font-size: 14px;
          line-height: 19px;
          padding: 2px 5px;
          margin-left: 10px;
          margin-top: 20px;
        }
      }

      .nav-tabs {
        margin-top: 20px;
        @extend .nav-tabs-style-extension;
      }

      .tab-content {
        @extend .tab-content-style-extension;
      }
    }

    #gt_filters {
      padding-bottom: 20px;
      background-color: #f5f5f5;
      @include mq($until: desktop) {
        background-color: #fff;
      }

      h4 {
        color: #000;
        font-size: 18px;
        font-weight: 300;
        line-height: 24px;
        margin: 20px 0 9px 0;
        @include mq($until: desktop) {
          margin: 28px 0 9px 0;
        }
      }

      .form-inline {
        display: flex;
        @include mq($until: desktop) {
          flex-direction: column;
        }

        .form-group {
          width: 100%;
          margin-right: 10px;
          @include mq($until: desktop) {
            margin-right: 0;
          }

          .form-control {
            width: 100%;
            height: 30px;
            border: 1px solid $grey-color;
            border-radius: 6px;
            background-color: #fff;
            color: $grey-color;
            font-size: 14px;
            line-height: 19px;
            padding-left: 5px;
            @include mq($until: desktop) {
              height: 44px;
              margin-bottom: 19px;
            }
          }
        }

        #gt_search.btn {
          border: 1px solid $grey-color;
          border-radius: 6px;
          background-color: $grey-color;
          color: #fff;
          font-size: 14px;
          line-height: 19px;
          @include mq($until: desktop) {
            height: 44px;
            width: 69px;
          }
        }
      }

      .btn.gt_load_schedule {
        color: $blue-color;
        font-size: 14px;
        line-height: 22px;
        border: none;
        background-color: transparent;
      }
    }
  }

  /* Styles for GUEST DETAIL page */
  .v-grow-tix-view__guest-bio-cta {
    &--before-content {
      padding-top: 24px;
    }

    &--after-content {
      padding-bottom: 24px;
      text-align: center;
    }
  }

  .v-grow-tix-view__guest-bio {
    color: #000;
    padding: 42px 0;
    @include mq($until: desktop) {
      padding-top: 30px;
    }

    .row {
      div {
        &:nth-child(3).col-sm-6,
        &:nth-child(5).col-sm-6 {
          hr {
            display: block;
            visibility: hidden;
          }

          br {
            display: none;
          }

          h3 {
            color: #000;
            font-family: 'Proxima Nova ExCn Rg', sans-serif;
            font-size: 32px;
            font-weight: bold;
            line-height: 25px;
            margin: 22px 0 12px 0;

            @include mq($until: tablet) {
              margin: 50px 0 16px 0;
            }
          }

          h4.gt-blk {
            color: #000;
            font-size: 16px;
            line-height: 25px;
          }

          .alert.alert-info {
            color: $grey-color;
            font-size: 14px;
            line-height: 19px;
            text-transform: lowercase;
            text-align: left;
            margin-top: 16px;
            @include mq($until: tablet) {
              margin-top: 12px;
            }
          }
        }

        &:nth-child(2),
        &:nth-child(5) {
          flex-basis: 28%;
          @include mq($until: desktop) {
            flex-basis: 45%;
          }
          @include mq($until: tablet) {
            flex-basis: 100%;
          }
        }

        &:nth-child(5).col-sm-12 {
          flex-basis: 100%;
        }

        &:nth-child(6).col-sm-6,
        &:nth-child(5).col-sm-12 {
          h3 {
            color: #000;
            font-size: 18px;
            font-weight: 300;
            line-height: 24px;
            margin: 20px 0 16px 0;
            @include mq($until: tablet) {
              margin: 50px 0 16px 0;
            }
          }

          p {
            margin: 0;
            color: $grey-color;
            font-size: 14px;
            line-height: 19px;
          }
        }

        &:last-child.col-sm-12 {
          hr {
            display: block;
            margin: 70px 0 20px 0;
            background-color: $border-bg;
            @include mq($until: tablet) {
              margin: 62px 0 23px 0;
            }
          }

          h3 {
            color: #000;
            font-family: 'Proxima Nova ExCn Rg', sans-serif;
            font-size: 32px;
            font-weight: bold;
            line-height: 25px;
            margin-bottom: 36px;
          }
        }
      }

      hr {
        display: none;
      }

      h1.gt_guest_name {
        color: #000;
        font-family: 'Proxima Nova ExCn Rg', sans-serif;
        font-size: 44px;
        font-weight: bold;
        line-height: 38px;
        margin-bottom: 8px;
      }

      h5.gt_known_for {
        color: #000;
        font-size: 18px;
        font-weight: 300;
        line-height: 24px;
        margin-bottom: 29px;
        font-style: normal !important;
      }

      .img-responsive {
        width: 314px;
        height: 314px;
        @include mq($until: tablet) {
          width: 168px;
          height: 168px;
          margin-bottom: 16px;
        }
      }

      .nav-tabs {
        @extend .nav-tabs-style-extension;
      }

      .tab-content {
        @extend .tab-content-style-extension;
      }
    }
  }
}
