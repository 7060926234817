@font-face {
  font-family: 'Proxima Nova Regular';
  src: url('../../assets/fonts/proxima-nova/Proxima-Nova-Regular.woff2')
      format('woff2'),
    url('../../assets/fonts/proxima-nova/Proxima-Nova-Regular.woff')
      format('woff'),
    url('../../assets/fonts/proxima-nova/Proxima-Nova-Regular.otf')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'Proxima Nova ExCn Rg';
  src: url('../../assets/fonts/proxima-nova/ProximaNovaExCn-Regular.eot');
  src: local('Proxima Nova Extra Condensed Regular'),
    local('ProximaNovaExCn-Regular'),
    url('../../assets/fonts/proxima-nova/ProximaNovaExCn-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('../../assets/fonts/proxima-nova/ProximaNovaExCn-Regular.woff2')
      format('woff2'),
    url('../../assets/fonts/proxima-nova/ProximaNovaExCn-Regular.woff')
      format('woff'),
    url('../../assets/fonts/proxima-nova/ProximaNovaExCn-Regular.ttf')
      format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

//@font-face {
//  font-family: 'Proxima Nova ExCn Th';
//  src: url('../../assets/fonts/proxima-nova/ProximaNovaExCn-Extrabld.eot');
//  src: local('Proxima Nova Extra Condensed Extrabold'),
//    local('ProximaNovaExCn-Extrabld'),
//    url('../../assets/fonts/proxima-nova/ProximaNovaExCn-Extrabld.eot?#iefix')
//      format('embedded-opentype'),
//    url('../../assets/fonts/proxima-nova/ProximaNovaExCn-Extrabld.woff2')
//      format('woff2'),
//    url('../../assets/fonts/proxima-nova/ProximaNovaExCn-Extrabld.woff')
//      format('woff'),
//    url('../../assets/fonts/proxima-nova/ProximaNovaExCn-Extrabld.ttf')
//      format('truetype');
//  font-weight: 800;
//  font-style: normal;
//  font-display: swap;
//}

//@font-face {
//  font-family: 'Proxima Nova ExCn Lt';
//  src: url('../../assets/fonts/proxima-nova/ProximaNovaExCn-Semibold.eot');
//  src: local('Proxima Nova Extra Condensed Semibold'),
//    local('ProximaNovaExCn-Semibold'),
//    url('../../assets/fonts/proxima-nova/ProximaNovaExCn-Semibold.eot?#iefix')
//      format('embedded-opentype'),
//    url('../../assets/fonts/proxima-nova/ProximaNovaExCn-Semibold.woff2')
//      format('woff2'),
//    url('../../assets/fonts/proxima-nova/ProximaNovaExCn-Semibold.woff')
//      format('woff'),
//    url('../../assets/fonts/proxima-nova/ProximaNovaExCn-Semibold.ttf')
//      format('truetype');
//  font-weight: 600;
//  font-style: normal;
//  font-display: swap;
//}

@font-face {
  font-family: 'Proxima Nova ExCn Rg';
  src: url('../../assets/fonts/proxima-nova/ProximaNovaExCn-Bold.eot');
  src: local('Proxima Nova Extra Condensed Bold'), local('ProximaNovaExCn-Bold'),
    url('../../assets/fonts/proxima-nova/ProximaNovaExCn-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('../../assets/fonts/proxima-nova/ProximaNovaExCn-Bold.woff2')
      format('woff2'),
    url('../../assets/fonts/proxima-nova/ProximaNovaExCn-Bold.woff')
      format('woff'),
    url('../../assets/fonts/proxima-nova/ProximaNovaExCn-Bold.ttf')
      format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

//@font-face {
//  font-family: 'Proxima Nova ExCn Bl';
//  src: url('../../assets/fonts/proxima-nova/ProximaNovaExCn-BlackIt.eot');
//  src: local('Proxima Nova Extra Condensed Black Italic'),
//    local('ProximaNovaExCn-BlackIt'),
//    url('../../assets/fonts/proxima-nova/ProximaNovaExCn-BlackIt.eot?#iefix')
//      format('embedded-opentype'),
//    url('../../assets/fonts/proxima-nova/ProximaNovaExCn-BlackIt.woff2')
//      format('woff2'),
//    url('../../assets/fonts/proxima-nova/ProximaNovaExCn-BlackIt.woff')
//      format('woff'),
//    url('../../assets/fonts/proxima-nova/ProximaNovaExCn-BlackIt.ttf')
//      format('truetype');
//  font-weight: 900;
//  font-style: italic;
//  font-display: swap;
//}

//@font-face {
//  font-family: 'Proxima Nova ExCn Lt';
//  src: url('../../assets/fonts/proxima-nova/ProximaNovaExCn-SemiboldIt.eot');
//  src: local('Proxima Nova Extra Condensed Semibold Italic'),
//    local('ProximaNovaExCn-SemiboldIt'),
//    url('../../assets/fonts/proxima-nova/ProximaNovaExCn-SemiboldIt.eot?#iefix')
//      format('embedded-opentype'),
//    url('../../assets/fonts/proxima-nova/ProximaNovaExCn-SemiboldIt.woff2')
//      format('woff2'),
//    url('../../assets/fonts/proxima-nova/ProximaNovaExCn-SemiboldIt.woff')
//      format('woff'),
//    url('../../assets/fonts/proxima-nova/ProximaNovaExCn-SemiboldIt.ttf')
//      format('truetype');
//  font-weight: 600;
//  font-style: italic;
//  font-display: swap;
//}

//@font-face {
//  font-family: 'Proxima Nova ExCn Rg';
//  src: url('../../assets/fonts/proxima-nova/ProximaNovaExCn-BoldIt.eot');
//  src: local('Proxima Nova Extra Condensed Bold Italic'),
//    local('ProximaNovaExCn-BoldIt'),
//    url('../../assets/fonts/proxima-nova/ProximaNovaExCn-BoldIt.eot?#iefix')
//      format('embedded-opentype'),
//    url('../../assets/fonts/proxima-nova/ProximaNovaExCn-BoldIt.woff2')
//      format('woff2'),
//    url('../../assets/fonts/proxima-nova/ProximaNovaExCn-BoldIt.woff')
//      format('woff'),
//    url('../../assets/fonts/proxima-nova/ProximaNovaExCn-BoldIt.ttf')
//      format('truetype');
//  font-weight: bold;
//  font-style: italic;
//  font-display: swap;
//}

//@font-face {
//  font-family: 'Proxima Nova ExCn Th';
//  src: url('../../assets/fonts/proxima-nova/ProximaNovaExCn-Thin.eot');
//  src: local('Proxima Nova Extra Condensed Thin'), local('ProximaNovaExCn-Thin'),
//    url('../../assets/fonts/proxima-nova/ProximaNovaExCn-Thin.eot?#iefix')
//      format('embedded-opentype'),
//    url('../../assets/fonts/proxima-nova/ProximaNovaExCn-Thin.woff2')
//      format('woff2'),
//    url('../../assets/fonts/proxima-nova/ProximaNovaExCn-Thin.woff')
//      format('woff'),
//    url('../../assets/fonts/proxima-nova/ProximaNovaExCn-Thin.ttf')
//      format('truetype');
//  font-weight: 100;
//  font-style: normal;
//  font-display: swap;
//}

//@font-face {
//  font-family: 'Proxima Nova ExCn Lt';
//  src: url('../../assets/fonts/proxima-nova/ProximaNovaExCn-LightIt.eot');
//  src: local('Proxima Nova Extra Condensed Light Italic'),
//    local('ProximaNovaExCn-LightIt'),
//    url('../../assets/fonts/proxima-nova/ProximaNovaExCn-LightIt.eot?#iefix')
//      format('embedded-opentype'),
//    url('../../assets/fonts/proxima-nova/ProximaNovaExCn-LightIt.woff2')
//      format('woff2'),
//    url('../../assets/fonts/proxima-nova/ProximaNovaExCn-LightIt.woff')
//      format('woff'),
//    url('../../assets/fonts/proxima-nova/ProximaNovaExCn-LightIt.ttf')
//      format('truetype');
//  font-weight: 300;
//  font-style: italic;
//  font-display: swap;
//}

//@font-face {
//  font-family: 'Proxima Nova ExCn Rg';
//  src: url('../../assets/fonts/proxima-nova/ProximaNovaExCn-RegularIt.eot');
//  src: local('Proxima Nova Extra Condensed Regular Italic'),
//    local('ProximaNovaExCn-RegularIt'),
//    url('../../assets/fonts/proxima-nova/ProximaNovaExCn-RegularIt.eot?#iefix')
//      format('embedded-opentype'),
//    url('../../assets/fonts/proxima-nova/ProximaNovaExCn-RegularIt.woff2')
//      format('woff2'),
//    url('../../assets/fonts/proxima-nova/ProximaNovaExCn-RegularIt.woff')
//      format('woff'),
//    url('../../assets/fonts/proxima-nova/ProximaNovaExCn-RegularIt.ttf')
//      format('truetype');
//  font-weight: normal;
//  font-style: italic;
//  font-display: swap;
//}

//@font-face {
//  font-family: 'Proxima Nova ExCn Th';
//  src: url('../../assets/fonts/proxima-nova/ProximaNovaExCn-ThinIt.eot');
//  src: local('Proxima Nova Extra Condensed Thin Italic'),
//    local('ProximaNovaExCn-ThinIt'),
//    url('../../assets/fonts/proxima-nova/ProximaNovaExCn-ThinIt.eot?#iefix')
//      format('embedded-opentype'),
//    url('../../assets/fonts/proxima-nova/ProximaNovaExCn-ThinIt.woff2')
//      format('woff2'),
//    url('../../assets/fonts/proxima-nova/ProximaNovaExCn-ThinIt.woff')
//      format('woff'),
//    url('../../assets/fonts/proxima-nova/ProximaNovaExCn-ThinIt.ttf')
//      format('truetype');
//  font-weight: 100;
//  font-style: italic;
//  font-display: swap;
//}

//@font-face {
//  font-family: 'Proxima Nova ExCn Lt';
//  src: url('../../assets/fonts/proxima-nova/ProximaNovaExCn-Light.eot');
//  src: local('Proxima Nova Extra Condensed Light'),
//    local('ProximaNovaExCn-Light'),
//    url('../../assets/fonts/proxima-nova/ProximaNovaExCn-Light.eot?#iefix')
//      format('embedded-opentype'),
//    url('../../assets/fonts/proxima-nova/ProximaNovaExCn-Light.woff2')
//      format('woff2'),
//    url('../../assets/fonts/proxima-nova/ProximaNovaExCn-Light.woff')
//      format('woff'),
//    url('../../assets/fonts/proxima-nova/ProximaNovaExCn-Light.ttf')
//      format('truetype');
//  font-weight: 300;
//  font-style: normal;
//  font-display: swap;
//}

@font-face {
  font-family: 'Proxima Nova ExCn Bl';
  src: url('../../assets/fonts/proxima-nova/ProximaNovaExCn-Black.eot');
  src: local('Proxima Nova Extra Condensed Black'),
    local('ProximaNovaExCn-Black'),
    url('../../assets/fonts/proxima-nova/ProximaNovaExCn-Black.eot?#iefix')
      format('embedded-opentype'),
    url('../../assets/fonts/proxima-nova/ProximaNovaExCn-Black.woff2')
      format('woff2'),
    url('../../assets/fonts/proxima-nova/ProximaNovaExCn-Black.woff')
      format('woff'),
    url('../../assets/fonts/proxima-nova/ProximaNovaExCn-Black.ttf')
      format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

//@font-face {
//  font-family: 'Proxima Nova ExCn Th';
//  src: url('../../assets/fonts/proxima-nova/ProximaNovaExCn-ExtrabldIt.eot');
//  src: local('Proxima Nova Extra Condensed Extrabold Italic'),
//    local('ProximaNovaExCn-ExtrabldIt'),
//    url('../../assets/fonts/proxima-nova/ProximaNovaExCn-ExtrabldIt.eot?#iefix')
//      format('embedded-opentype'),
//    url('../../assets/fonts/proxima-nova/ProximaNovaExCn-ExtrabldIt.woff2')
//      format('woff2'),
//    url('../../assets/fonts/proxima-nova/ProximaNovaExCn-ExtrabldIt.woff')
//      format('woff'),
//    url('../../assets/fonts/proxima-nova/ProximaNovaExCn-ExtrabldIt.ttf')
//      format('truetype');
//  font-weight: 800;
//  font-style: italic;
//  font-display: swap;
//}
