@import '../../../../../styles/shared';

@include one-of-themes(
  [$FANEXPOHQ,
  $ONEOFAKIND,
  $INTERIORDESIGN,
  $ARTTORONTO,
  $THEARTISTPROJECT,
  $NAJAHSTACK,
  $MEFCC]
) {
  .c-fan-expo-hq-footer {
    padding: 25px 0;
    @include mq($until: tablet) {
      padding: 40px 0;
    }

    &__logo {
      max-width: 100%;
      max-height: 80px;
    }

    &__logo-mobile-wrapper {
      margin-bottom: 34px;
      @include mq($from: tablet) {
        display: none;
      }
    }

    &__logo-wrapper {
      @include margin-right(70px);
      @include mq($until: tablet) {
        display: none;
      }
    }

    &__pages-wrapper {
      display: flex;
      justify-content: flex-start;

      @include mq($until: tablet) {
        flex-direction: column;
      }
    }

    &__social-wrapper {
      display: flex;
      justify-content: flex-end;
    }
  }
}

@include one-of-themes(
  [$ONEOFAKIND,
  $INTERIORDESIGN,
  $ARTTORONTO,
  $THEARTISTPROJECT]
) {
  .c-fan-expo-hq-footer {
    background-color: #000 !important;

    &__logo {
      width: auto;
      height: 80px;
    }

    &__pages-wrapper {
      @include mq($until: tablet) {
        flex-direction: column;
      }
    }

    &__logo-wrapper {
      @include margin-right(50px);
      @include mq($until: desktop) {
        display: none;
      }
    }

    &__logo-mobile-wrapper {
      display: block;
      @include mq($from: desktop) {
        display: none;
      }
    }
  }
}

@include theme-only($ONEOFAKIND) {
  .c-fan-expo-hq-footer {
    font-family: 'GT America Thin', sans-serif;
  }
}

@include theme-only($THEARTISTPROJECT) {
  .c-fan-expo-hq-footer {
    font-family: 'Source Sans Pro', sans-serif;
  }
}

@include theme-only($ARTTORONTO) {
  .c-fan-expo-hq-footer {
    font-family: 'Barlow Regular', sans-serif;
    font-weight: 400;
  }
}

@include theme-only($INTERIORDESIGN) {
  .c-fan-expo-hq-footer {
    font-family: 'Gibson', sans-serif;
  }
}

@include theme-only($NAJAHSTACK) {
  .c-fan-expo-hq-footer {
    font-family: 'Proxima Nova Regular', sans-serif;
  }
}
